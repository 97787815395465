<template>
  <div class="v-login">
    <div class="v-login__logo-wrapper">
      <router-link :to="{ name: 'Home' }">
        <img class="v-login__logo" alt="" src="../assets/logo.png" />
      </router-link>
    </div>
    <div class="v-login__wrapper">
      <div class="v-login__cols">
        <div class="v-login__col">
          <VHeading header="h1" class="v-login__heading">
            {{ $t('LOGIN_HEADING') }}
          </VHeading>
          <p class="v-login__text" v-html="$t('LOGIN_TEXT')" />
          <TheButton class="v-login__button" @click.native="handleStudent">
            {{ $t('LOGIN_BUTTON_STUDENT') }}
          </TheButton>
          <TheButton class="v-login__button" @click.native="login">
            {{ $t('LOGIN_BUTTON') }}
          </TheButton>
        </div>
      </div>
    </div>
    <div class="v-login__bottom-wrapper">
      <div class="v-login__bottom-box">
        <p v-html="$t('LOGIN_BOTTOM_TEXT')" />

        <a
          href="https://www.fagbokforlaget.no/Verk/Echo"
          target="_blank"
          rel="noopener noreferrer"
          >{{ $t('LOGIN_READ_MORE_TEXT') }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from '@vue/composition-api'
import TheButton from '@forlagshuset/v-button'
import VHeading from '@forlagshuset/v-heading'
import auth from '@/auth'

export default {
  components: {
    TheButton,
    VHeading,
  },

  setup(props, vm) {
    onMounted(() => {
      document.querySelector('#app').classList.add('login-page')
      auth.checkToken().then(user => {
        vm.root.$store.dispatch('user/setToken')
        vm.root.$store.dispatch('user/setUser', user)
        vm.root.$store.dispatch('appConfig/setCheckedAccess', true)
        vm.root.$router.push('/')
      })
    })

    onUnmounted(() => {
      document.querySelector('#app').classList.remove('login-page')
    })

    const handleStudent = () => {
      window.localStorage.setItem('hasStudentAccess', 'true')
      vm.root.$router.push('/')
    }

    const login = () =>
      auth.authorize({
        redirect_url: window.hostname,
      })

    return {
      handleStudent,
      login,
    }
  },
}
</script>

<style lang="scss">
.v-login {
  background: $color-brand;
  padding: 1rem;
  background-image: url('/img/bg.svg');
  background-position: -2rem 7rem;
  background-size: auto 50%;
  background-repeat: no-repeat;
  min-height: 100vh;
  @media (min-width: 768px) {
    padding: 1.25rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.v-login__logo-wrapper {
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
}
.v-login__logo {
  max-width: 4.375rem;
  width: 100%;
  display: inline-block;
  @media (min-width: 768px) {
    max-width: 5.75rem;
  }
}

.v-login__button {
  background: $color-white;
  color: $color-black;
  border-radius: 1.2rem;
  margin-top: 0.625rem;
  border: none;
  min-width: 330px;
  max-width: 380px;
  width: 100%;
  font-size: 1.5rem;
  padding: 1rem;
  font-weight: 600;
  transition: background 0.3s ease-in;

  @media (min-width: 768px) {
    padding: 1rem;
    margin-top: 2rem;
  }

  &:hover {
    background: $color-black;
    color: $color-white;
  }
}

.v-login__wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  @media (min-width: 768px) {
    padding: 0 1rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-login__cols {
  margin-top: 1rem;

  @media (min-width: 768px) {
    display: flex;
  }
}

.v-login__col {
  padding: 0;
  text-align: center;
  @media (min-width: 768px) {
    padding: 1rem;
  }

  p {
    font-size: 1.25rem;
    padding: 0;
    margin-top: 0.4375rem;
    margin-bottom: 3.5625rem;
    @media (min-width: 768px) {
      margin-top: 0.8125rem;
      margin-bottom: 0;
      font-size: 1.5rem;
    }
  }
}

.v-login__heading {
  font-weight: 600;

  h1 {
    font-size: 1.75rem;
    margin-top: 0;
    margin-bottom: 0;
    @media (min-width: 768px) {
      margin-top: 16.75rem;
      font-size: 4rem;
    }
  }
}
.v-login__text {
  font-size: 1.5rem;
  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
}

.v-login__bottom-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  @media (min-width: 768px) {
    margin: 10.5rem auto 0;
    padding: 0 1rem;
  }
}
.v-login__bottom-box {
  text-align: center;
  padding: 1rem 0;
  @media (min-width: 768px) {
    padding: 1rem;
  }
  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
  }

  a {
    font-size: 1rem;
    font-weight: 700;
    color: $login-link;
    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }
}
</style>
